// Generated by Framer (667f196)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useActiveVariantCallback, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";

const cycleOrder = ["ytn3DNN0X"];

const variantClassNames = {ytn3DNN0X: "framer-v-1ohsd11"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; tap?: any }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style, className, width, height, layoutId, variant: outerVariant = "ytn3DNN0X", tap: TdKxtAwfi, ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "ytn3DNN0X", transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTap14rbp5u = activeVariantCallback(async (...args) => {
if (TdKxtAwfi) {
const res = await TdKxtAwfi(...args);
if (res === false) return false;
}
})

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-eiPIE", classNames)} style={{display: "contents"}}>
<motion.div {...restProps} className={cx("framer-1ohsd11", className)} data-framer-name={"Page 1"} data-highlight layoutDependency={layoutDependency} layoutId={"ytn3DNN0X"} onTap={onTap14rbp5u} ref={ref} style={{...style}} transition={transition}/>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-eiPIE [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-eiPIE * { box-sizing: border-box; }", ".framer-eiPIE .framer-1q7kjv1 { display: block; }", ".framer-eiPIE .framer-1ohsd11 { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 12px; height: min-content; justify-content: center; min-height: 51px; min-width: 144px; overflow: visible; padding: 0px 0px 0px 0px; position: relative; width: min-content; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-eiPIE .framer-1ohsd11 { gap: 0px; } .framer-eiPIE .framer-1ohsd11 > * { margin: 0px; margin-left: calc(12px / 2); margin-right: calc(12px / 2); } .framer-eiPIE .framer-1ohsd11 > :first-child { margin-left: 0px; } .framer-eiPIE .framer-1ohsd11 > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 51
 * @framerIntrinsicWidth 144
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]}}}
 * @framerVariables {"TdKxtAwfi":"tap"}
 */
const Framerh1mIVgshS: React.ComponentType<Props> = withCSS(Component, css, "framer-eiPIE") as typeof Component;
export default Framerh1mIVgshS;

Framerh1mIVgshS.displayName = "Elements/Pagination Numbers";

Framerh1mIVgshS.defaultProps = {height: 51, width: 144};

addPropertyControls(Framerh1mIVgshS, {TdKxtAwfi: {title: "Tap", type: ControlType.EventHandler}} as any)

addFonts(Framerh1mIVgshS, [])